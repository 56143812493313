.modalContent {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}
.incentiveModal {
  h1 {
    font-size: 30px;
    font-weight: 510;
    line-height: 36px;
    padding-bottom: 66px;
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 42px;
  }
  .btn {
    color: var(--color-secondary);
    margin-top: 32px;
    margin-right: 24px;
    margin-bottom: 76px;
  }
}
