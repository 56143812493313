@import "@styles/lucidmixins";

.vertCarousel {
  display: flex;
  flex-direction: column;
  overflow-y: visible;
}

.grid {
  display: grid;
  grid-template-areas:
    'first second third'
    'fourth fifth sixth';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 30px 30px;
  margin-left: -15px;

    .survey {
      padding: 0 !important;
      min-width: 0 !important;
      min-height: 0 !important;
    }

    .survey-1 {
      grid-area: first;
    }

    .survey-2 {
      grid-area: second;
    }

    .survey-3 {
      grid-area: third;
    }

    .survey-4 {
      grid-area: fourth;
    }

    .survey-5 {
      grid-area: fifth;
    }

    .survey-6 {
      grid-area: sixth;
    }

    @media screen and (min-width: 820px) {
      grid-gap: 30px 15px;
      margin-left: 0;
    }
}

.surveyPartner {
  @include lucid-cta(margin-bottom);
}

.surveyCard {
  @media screen and (min-width: 768px) {
    height: 160px !important;
  }
}
