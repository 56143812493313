.banner {
  align-self: center;
  background: var(--color-gray);
  border-radius: 20px;

  .body {
    font-size: 16px;
    line-height: 19px;
  }

  .heading {
    font-size: 20px;
    line-height: 24px;
  }

  .cardBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  @media screen and (min-width: 428px) {
    .cardBar {
      justify-content: space-around;
    }
  }
}

@mixin bannerCard {
  height: 120px;
  max-height: 120px;
  width: 150px;

  .content {
    margin-bottom: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-top: 8px;
  }

  .ctaFooter {
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    height: 38px;
    max-height: 38px;
  }

  .body {
    font-size: 16px;
    line-height: 21px;
  }

  .heading {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
  }

  .p,
  p {
    margin-bottom: 6px;
  }
}
