.modal {
  background-color: white;
  padding-left: 0 !important;
}

.modalContent {
  border: 0;
  position: relative;
}

.modalBody {
  padding: 0 0.5rem;
}

.closeButton {
  color: var(--color-secondary) !important;
  margin-left: auto;
  margin-right: 0;
}

.header {
  h2 {
    font-size: 35px;
    color: var(--color-dark-green);
  }

  p {
    margin-top: 16px;
    font-size: 16px;
    color: var(--color-light-green);
  }
}

.listContent {
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--color-gray);

  ul {
    padding-left: 1rem;
    list-style: none;

    li {
      color: var(--color-light-green);

      &::before {
        content: "\2022";
        color: var(--color-primary);
        display: inline-block;
        transform: scale(2);
        margin-left: -0.9em;
        width: 0.8em;
      }
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
}

.linkCta {
  margin: 90px auto 0 auto;
  width: 70%;

  @media screen and (max-height: 667px) {
    margin-top: 40px;
  }
}
