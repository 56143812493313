.content {
  min-height: 116px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  margin: 0 16px;

  .leftCol {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -webkit-box-pack: center;
    width: 63%;
    margin-right: 2%;

    .info {
      width: 100%;

      h4 {
        font-weight: 510;
        font-size: 16px;
        line-height: 19px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .incentive {
      font-weight: 510;
      font-size: 16px;
      line-height: 19px;
    }

    .verticalLine {
      width: 1px;
      height: 12px;
      margin: 0 8px;
    }

    .length {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .rightCol {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: end;
    -webkit-box-pack: center;
    width: 35%;

    .surveyCta {
      color: #fdfdff;
      min-width: 90px;
      height: 34px;
      font-size: 16px;
      line-height: 19px;
      white-space: nowrap;
    }
  }
}
