@mixin lucid-cta($margin) {
  #{$margin}: 12px;
  padding: 20px 12px;

  img {
    margin: 18px 12px;
  }

  @media screen and (max-width: 425px) {
    img {
      margin-left: 4px !important;
    }
  }
}
