@import '../signUpBanner.module.scss';

.option2 {
  @include bannerCard;
  background: var(--color-white);
  color: var(--color-text);

  .ctaFooter {
    background: var(--color-gray-dark);
    color: var(--color-white);
  }

  .heading {
    color: var(--color-primary-dark);
  }
}

.option2WithPaidSurveys {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 400px;

  .content {
    padding: 20px;
  }

  .ctaFooter {
    height: 100%;
    max-height: 100%;
  }
}
