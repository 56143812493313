@import '../signUpBanner.module.scss';

.preferred {
  @include bannerCard;
  background: var(--color-gradient);
  color: var(--color-white);

  .ctaFooter {
    background: var(--color-secondary);
  }
}

.preferredWithPaidSurveys {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 400px;

  .content {
    padding: 20px;
  }

  .ctaFooter {
    height: 100%;
    max-height: 100%;
  }
}
