@import "@styles/buttonmixins";
@import "../standardCard.module.scss";
$button-color: var(--color-secondary);

.noDecoration {
  &:hover, &:visited, &:link, &:active
  {
      text-decoration: none;
  }
}

.ctacard {
  border-radius: 10px;
  max-width: 343px;
  max-height: 250px;
  cursor: pointer;

  .backgroundImage {
    border-radius: 10px 10px 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content {
    hr {
      height: 2px;
      background-color: var(--color-white);
      opacity: 1;
      width: 52px;
    }
    h3 {
      font-size: 25px;
      line-height: 30px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
    }
    .button {
      @include button-hover-theme($button-color, light);
      font-size: 14px;
      line-height: 17px;
      padding: 9px 26px;
      &:hover {
        border: 2px solid var(--color-white) !important;
      }
    }
  }
  .ctaFooter {
    border-radius: 0 0 10px 10px;
    cursor: pointer;
    padding: 0;
    p {
      margin: 1em;
    }
  }
}
