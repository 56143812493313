$card-background: var(--color-base-white);
$card-box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);

.standard {
  min-height: 120px;
  background: $card-background;
  box-shadow: $card-box-shadow;
  border-radius: 20px;
  border: none;
}

.card {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
