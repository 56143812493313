.surveyPartnersWithoutImage {
  background-color: var(--color-white);
  color: var(--color-body);
  box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
  border-radius: 20px;
  border: 3px solid var(--color-secondary);
  text-decoration: none !important;
  padding: 15px !important;
  cursor: pointer;
  img {
    margin: 18px 21px 18px 34px;
  }
  .header {
    p {
      color: var(--color-secondary);
      font-size: 18px;
      margin: 0;
    }
    .iconWrapper {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--color-gray);
      display: grid;
      place-items: center;

      .icon {
        color: var(--color-secondary);
        transform: scale(0.6);
      }
    }
  }
  .content {
    p {
      color: var(--color-text);
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
    .ctaPill {
      background-color: var(--color-secondary);
      color: white;
      border-radius: 50px;
      padding: 7px;
      margin-left: 10px;
      width: 124px;
    }
  }
}
