.wrapper {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.15);

  a {
    text-decoration: none !important;
  }

  h6 {
    font-size: 16px;
    font-weight: 500;
    width: 95%;
    color: var(--color-dark-green);
  }

  .column {
    &:nth-child(2) {
      margin-left: 15px;

      .iconWrapper {
        .icon {
          transform: scale(1.3);
        }
      }
    }

    .iconWrapper {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--color-gray);
      display: grid;
      place-items: center;

      .icon {
        color: var(--color-secondary);
        transform: scale(0.6);
      }
    }

    .columnContent {
      margin-left: 15px;

      span {
        font-size: 10px;
        color: var(--color-premium-gray);
      }

      p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        color: var(--color-text);
      }
    }
  }

  .chevronRight {
    color: var(--color-secondary);
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(0%, -50%);
  }
}
