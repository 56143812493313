.surveyPartner {
  position: relative;
  background-color: var(--color-white);
  color: var(--color-body);
  box-shadow: 3px 2px 10px 1px rgba(157, 156, 181, 0.3);
  border-radius: 20px;
  text-decoration: none !important;
  padding: 15px !important;
  cursor: pointer;
  .header {
    p {
      color: var(--color-secondary);
      font-size: 16px;
      margin: 0 0 0 10px;
    }

    .iconWrapper {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--color-gray);
      display: grid;
      place-items: center;

      .icon {
        color: var(--color-secondary);
        transform: scale(0.6);
      }
    }
  }
  .content {
    p {
      color: var(--color-text);
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      width: 70%;

      @media screen and (min-width: 375px) {
        width: 80%;
      }
    }

    .ctaPill {
      border: 2px solid var(--color-secondary);
      color: var(--color-secondary);
      border-radius: 50px;
      padding: 5px;
      width: 140px;

      @media screen and (min-width: 375px) {
        width: 160px;
      }
    }
  }
}

.image {
  position: absolute;
  bottom: 12px;
  right: 0px;
}
